<template>
    <div>
        <nav-bar header-title="课包抵扣券" :show-back="true" :header-back="newAppBack" />
        <app-page id="box" class="page-img" :style="appTypeStr === 'mini'? 'min-height: 100vh': ''">
            <div class="coupon-box content">
                <!--                <van-list-->
                <!--                    v-model="loading"-->
                <!--                    :finished="finished"-->
                <!--                    finished-text="没有更多了"-->
                <!--                    @load="getCouponList"-->
                <!--                    class="coupon-list"-->
                <!--                    :immediate-check="false"-->
                <!--                    v-if="couponList.length"-->
                <!--                >-->
                <div v-if="list.length" class="coupon-list">
                    <div class="item" v-for="(item, index) in list" :key="index">
                        <div class="item-i" :class="{'active': item.active}">
                            <div class="item-l col-center-center">
                                <div><span>¥</span>{{ item.discount }}</div>
<!--                                <div>{{ item.fullReduction }}</div>-->
                            </div>
                            <div class="item-r">
                                <img class="arrow" @click.stop="openTips(item)" :class="{'rotate': item.active}"
                                     src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-down.png"
                                     alt="">
                                <div class="coupon-name">{{ item.cashVoucherUserName }}</div>
                                <div class="coupon-tabs">
                                    <span v-for="v in item.typeList" :key="v">{{ v }}</span>
                                </div>
                                <div class="coupon-date">{{item.efficientDate }}</div>
                            </div>
                        </div>
                        <div class="instructions" :class="{'active': item.active, 'close': item.active !== undefined && !item.active}"><p>
                            {{item.instructions}}
                        </p></div>
                    </div>
                </div>
                <!--                </van-list>-->
                <div class="coupon-list" v-else>
                    <div class="empty">
                        <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="">
                    </div>

                </div>
            </div>
        </app-page>
    </div>
</template>

<script>
import userMixin from '@/mixin/userMixin';
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import {initBack, newAppBack} from "@/lib/appMethod";
import wx from "weixin-js-sdk";
export default {
    name: "myCoupon",
    mixins: [userMixin],
    components: {
        appPage,
        navBar,
    },
    data() {
        return {
            show: false,
            list: []
        }
    },
    async created() {
        initBack();
        await this.$getUserId();
        this.getCouponList()
    },
    methods: {
        newAppBack,
        getCouponList() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true
            })
            this.$axios.post(`${this.baseURLApp}/cashVoucher/user/current/listUser`, {
                hasMonthCard: false,
                hasBalance: false,
                hasCard: false,
                hasPackage: true,
                userId: this.userId,
                token: this.token,
            }).then(res => {
                this.list = res.data.usableList || []
                this.$toast.clear()
            }).catch(() => {
                this.$toast.clear()
            })
        },
        openTips(v){
            v.active = !v.active
            this.$forceUpdate()
        },
    },
}
</script>

<style scoped lang="less">
.instructions{
    //height: 0;
    width: 100%;
    overflow: hidden;
    max-height:0;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1) inset;
    box-sizing: border-box;
    white-space: pre-line;
}
.instructions p{
    padding: 24px;
    font-size: 20px;
    color: #9AA1A9;
    line-height: 32px;
}
.instructions.active{
    animation: open .5s forwards;
}
.instructions.close{
    animation: close .5s forwards;
}
@keyframes open {
    0%{
        max-height:0;
    }
    100%{
        max-height: 120px;
        overflow-y: scroll;
    }
}
@keyframes close {
    0%{
        max-height: 120px;
    }
    100%{
        max-height:0;
        overflow-y: hidden;
    }
}
.content {
    padding: 0 32px 32px;
    box-sizing: border-box;
    overflow-y: scroll;
}
.page-img{
    background: #F5F5F5;
}
.coupon-box {
    padding-bottom: 0;
}

.pay-btn {
    width: 686px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;
    color: #242831;
    font-size: 32px;
    margin-top: 72px;
    margin-bottom: 38px;
}

.coupon {
    height: 100px;
    box-sizing: border-box;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-right.png") no-repeat center right;
    background-size: 24px 24px;
    padding-right: 34px;
}
.coupon-title{
    font-weight: bold;
    color: #242831;
    font-size: 32px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.coupon-list{
    padding-bottom: 120px;
    .empty{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 300px;
        img{
            width: 320px;
            height: 320px;
        }
    }
}
.price {
    padding: 28px 0 32px;
}

.price > div .s1 {
    text-decoration: line-through;
    color: #6C727A;
    line-height: 28px;
    font-size: 24px;
    margin-right: 16px;
}

.price > div .s2 {
    font-family: BebasNeueBold;
    color: #242831;
    font-size: 24px;
    font-weight: bold;
}

.price > div .s3 {
    font-family: BebasNeueBold;
    color: #242831;
    font-size: 40px;
    font-weight: bold;
}

.left {
    font-size: 24px;
    color: #242831;
    line-height: 28px;
}






.coupon-box .item {
    background-color: white;
}

.coupon-box .item {
    margin-top: 32px;
}

.coupon-box .item-i {
    display: flex;
    justify-content: space-between;
    //align-items: center;
}
.coupon-box .item-l {
    width: 192px;
    height: 200px;
    background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/card-coupon-active.png");
    background-size: 100% 100%;
    color: white;
    font-size: 20px;
    flex-shrink: 0;
}
.coupon-box .item-l > div:first-child {
    font-family: BebasNeueBold;
    font-size: 80px;
}
.coupon-box .item-l> div:first-child span{
    font-size: 36px;
}
.coupon-box .item-r {
    flex-grow: 1;
    box-sizing: border-box;
    padding-left: 16px;
    position: relative;
}

.coupon-box .item-r .coupon-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;


}
.coupon-box .item-r .coupon-tabs span {
    padding: 4px 8px;
    font-weight: bold;
    color: #FB732E;
    font-size: 18px;
    border: 1px solid #FB732E;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 8px;
}
.coupon-box .item-r .coupon-date {
    margin-top: 38px;
    font-size: 20px;
    line-height: 23px;
    color: #6C727A;
    position: absolute;
    left: 16px;
    bottom: 24px;

}

.coupon-box .item-r .coupon-name {
    line-height: 28px;
    font-weight: bold;
    font-size: 24px;
    padding-top: 24px;
}
.goodsNum{
    margin-top: 28px;
}
.coupon-box .item-r .arrow {
    position: absolute;
    right: 4px;
    bottom: 8px;
    width: 24px;
    height: 24px;
    transition: transform .5s;
    padding: 20px;
}

.coupon-box .arrow.rotate {
    transform: rotateZ(180deg);
}
</style>
